/* Component: Header */
.Header {
	position: relative;
    background: #333;
	color: #fff;
	padding: 20px 0;
	padding-bottom: 10px;
	margin-bottom: 10px;
	text-align: center;
	border-bottom: 5px solid var(--accent-color);
}


.Header p {
	position: absolute;
	bottom: 0; 
	left: 0; 
}

/* Component: NavigationBar */

.NavigationBar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #3a3a3a;
    color: #fff;
    padding: 10px 20px;
    margin-bottom: 30px;
}

.NavigationBar a:hover {
    background-color: #2a2a2a;
}

.NavigationBar a {
    color: #fff;
    text-decoration: none;
    padding: 10px 20px; 
    border-radius: 15px; 
    transition: background-color 0.3s; 
}

/* Component: ProjectCard */
.ProjectsContainer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	padding: 20px;
}

.ProjectCard {
    position: relative;
    width: 100%;
    height: 300px; 
    perspective: 1000px; 
    
}

.CardInner {
    background: #3a3a3a; 
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    cursor: pointer;
    border-radius: 15px;
}

.CardInner.is-flipped {
    transform: rotateY(180deg);
}

.CardFront, .CardBack {
    background: #3a3a3a; 
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 15px;
}

.CardBack:hover, .CardFront:hover {
    background: #444;
}

.CardFront {
    display: flex;
    padding: 25px;
}

.CardBack {
    transform: rotateY(180deg);
    padding: 15px;
}

.CardBackContent {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding: 1px;
}

.CardBackContent::-webkit-scrollbar {
    width: 8px;
}