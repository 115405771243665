
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family:Verdana;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: var(--accent-color); 
}

*::-webkit-scrollbar-thumb {
    background-color: var(--accent-color); 
	border-radius: 10px;
    border: 2px solid #2a2a2a; 
}

*::-webkit-scrollbar-track {
    background: #2a2a2a; 
}

/* Style scrollbars */
body::-webkit-scrollbar {
    width: 20px;
}


body {
	font-family: 'Arial', sans-serif;
	line-height: 1.6;
	color: #f4f4f4;
	background-color: #333;
	padding: 20px;
	
}

.App {
	max-width: 960px;
	margin: auto;
	overflow: hidden;
	padding: 0 20px;
}

h1, h2 {
	margin-bottom: 20px;
}

p {
	margin-bottom: 10px;
}

.SocialMedia{
	display: flex;
}

.icon_img {
	width: 50px;
	height: 50px;
	margin: 5px;
}

.Skills{
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.SkillHeader{
	border-bottom: #444;
	border-width: 5px;
	border-bottom-style: solid;
	text-align: center;
}

.Skill {
	margin: 10px;
	padding: 10px;
	font-size: large;
}

/* Links */
.Links {
	display: flex;
	justify-content: space-around;
}

/* Style for the headers (About me, Work experience, */
h2 {
	margin-bottom: 30px;
	color: #eaeaea;
	font-size: 1.5em; 
}

/* Style for the text box */
.TextBox {
	background: #3a3a3a; 
	color: #fff;
	padding: 20px;
	margin: 10px 0 30px 0; 
	border-radius: 5px; 
}

/* Images in a text box */
.TextBox img {
	margin-right: 10px;
	vertical-align: middle;
}

.TextBox p {
	margin-bottom: 15px;
	font-size: medium;
}

.TextBox ul {
	margin-left: 40px;
}

.breakPoint {
	border-bottom: 1px solid #fff;
	margin: 10px 0;
	border-color: var(--accent-color);
}

.pdf {
	margin: 20px 0;
	text-align: center;
}
  
.pdf object {
	width: 100%;
	max-width: 600px;
	height: 700px; 
	border: none;
}
  

.ContactInfo a {
	color: #46bef5; 
}
  
.ContactInfo a:hover {
	font-weight: bold;
}
  

/* 	Fade Transition */
.fade-enter {
	opacity: 0;
	z-index: 1;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 300ms ease-in;
}

.fade-exit {
	opacity: 1;
}

.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 300ms ease-in;
}